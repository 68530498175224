import { Button, Container, Drawer, Typography } from "@material-ui/core";
import { Email, KeyboardArrowDownOutlined } from "@material-ui/icons";
import { useTranslation } from "next-export-i18n";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import data_country from "../public/data_country";
import Loading from "../src/components/Loading";
import Navbar from "../src/components/Navbar";
import PhoneCodePicker from "../src/components/PhoneCodePicker";
import ReusableDialog from "../src/components/ReusableDialog";
import { loginWithGoogle } from "../src/config/firebase";
import {
  checkAvailability,
  loginWithFirebaseToken,
} from "../src/services/rooms";
import useStyles from "../styles/login";

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const redirect = router.query.redirect;
  const lang = router.query.lang;
  const [isLoading, setIsLoading] = useState(false);
  const [openPhoneCodePicker, setOpenPhoneCodePicker] = useState(false);
  const [phoneCode, setPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [prevPhoneCode, setPrevPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [phone, setPhone] = useState("");
  const [register, setRegister] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectPhoneCode = (data) => {
    setPhoneCode(data);
    setOpenPhoneCodePicker(false);
  };

  const getCurrentDataCountry = (phoneNumber) => {
    const country = data_country.find((item) =>
      `+${phoneNumber}`.startsWith(item.dial_code)
    );
    if (country) {
      setPhoneCode(country);
      setPrevPhoneCode(country);
    }
  };

  useEffect(() => {
    const phoneData = localStorage.getItem("phoneData");
    if (phoneData) {
      getCurrentDataCountry(phoneData);
    }
  }, []);

  useEffect(() => {
    const phoneData = localStorage.getItem("phoneData");
    const dialCode = phoneCode.dial_code;
    if (phoneData) setPhone(phoneData.slice(dialCode.length - 1));
  }, [prevPhoneCode]);

  const handleChange = (event) => {
    let pattern = /^$|^[0-9]+$/;
    if (pattern.test(event.target.value)) {
      setPhone(event.target.value);
    } else {
      setPhone(event.target.value.replace(/[^0-9]/g, ""));
    }
  };

  const validateTypePhoneNumber = () => {
    const phoneNumber = phoneCode.dial_code.slice(1) + phone.replace(/^0+/, "");
    return phoneNumber;
  };

  const handleVerify = () => {
    const data = { phoneNumber: validateTypePhoneNumber() };
    setIsLoading(true);
    checkAvailability(data)
      .then((res) => {
        setIsLoading(false);
        setRegister(true);
        if (validateTypePhoneNumber()) {
          localStorage.setItem("phoneData", validateTypePhoneNumber());
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response.data.meta.error_code ===
          "PHONE_NUMBER_IS_ALREADY_IN_USE_ERROR"
        ) {
          if (redirect) {
            router.push(
              `/verify-method?method=sign-in&verifyMethod=phone&redirect=${redirect}&lang=${lang}`
            );
          } else {
            router.push(
              `/verify-method?method=sign-in&verifyMethod=phone&lang=${lang}`
            );
          }
          if (validateTypePhoneNumber()) {
            localStorage.setItem("phoneData", validateTypePhoneNumber());
          }
        } else {
          setIsError(true);
          setErrorMessage(error.response.data.meta.message);
        }
      });
  };

  const handleRegister = () => {
    if (redirect) {
      router.push(`/register?redirect=${redirect}&lang=${lang}`);
    } else {
      router.push(`/register?lang=${lang}`);
    }
  };

  const handleLoginWithEmail = () => {
    if (redirect) {
      router.push(
        `/login-with-email?method=sign-in&verifyMethod=email&redirect=${redirect}&lang=${lang}`
      );
    } else {
      router.push(
        `/login-with-email?method=sign-in&verifyMethod=email&lang=${lang}`
      );
    }
  };

  useEffect(() => {
    const asPath = localStorage.getItem("from");
    if (localStorage.getItem("token")) {
      if (asPath) {
        router.push(`${asPath}`);
        return;
      } else if (router.query.redirect) {
        router.push(`${(redirect as string).slice(1)}?lang=${lang}`);
      } else {
        router.push(`/?lang=${lang}`);
      }
    }
  }, []);

  const handleLoginGoogle = async () => {
    setIsLoading(true);
    const tokenFirebase = await loginWithGoogle();
    if (tokenFirebase !== undefined) {
      const response = await loginWithFirebaseToken(tokenFirebase);
      setIsLoading(false);
      localStorage.setItem("data_login", JSON.stringify(response.data));
      localStorage.setItem("token", response.data.token);
      if (response.meta.status_code === 200) {
        if (router.query.redirect) {
          router.push(`${(redirect as string).slice(1)}?lang=${lang || "id"}`);
        } else {
          router.push(`/?lang=${lang || "id"}`);
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Fragment>
        <Container maxWidth="xs" className={classes.root}>
          <Navbar title={t("login.log-in")} onClickBack={() => router.back()} />
          <div className={classes.wrapper}>
            <div className={classes.logoWrapper}>
              <img
                src={
                  process.env.NEXT_PUBLIC_COLOR_LOGO ||
                  "https://swaps-bucket.s3.ap-southeast-1.amazonaws.com/Group%2026.svg-2022-08-31T05%3A35%3A49.133Z"
                }
                style={{ height: 24 }}
              />
            </div>
            <div className={classes.textKeterangan}>
              <Typography variant="caption" align="center">
                <b>{t("login.log-in")}</b>{" "}
                {t(
                  "login.to-save-your-profile-and-feel-the-experience-explore-Dafam-Hotel-in-your-screen",
                  { name: process.env.NEXT_PUBLIC_NAME || "Pesan.io" }
                )}
              </Typography>
            </div>

            <div
              className={classes.inputWrapper}
              style={{
                marginBottom: 20,
              }}
            >
              <Typography variant="body2">{t("login.phone-number")}</Typography>
              <div className={classes.phoneCodeWrapper}>
                <div
                  className={classes.phoneCode}
                  onClick={() => setOpenPhoneCodePicker(true)}
                >
                  <img
                    src={`/img/flags/${phoneCode?.country_code.toLocaleLowerCase()}.png`}
                    height={30}
                    width={30}
                    alt=""
                  />
                  <Typography style={{ padding: "0px 6px" }}>
                    {phoneCode?.dial_code}
                  </Typography>
                  <KeyboardArrowDownOutlined />
                </div>
                <input
                  placeholder={t("register.example-phone-number")}
                  className={classes.inputPhone}
                  onChange={(event) => handleChange(event)}
                  value={phone}
                />
              </div>
            </div>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.buttonMasuk}
              onClick={handleVerify}
              disabled={phone?.length < 6 || !phone?.length ? true : false}
            >
              {t("login.log-in")}
            </Button>
            {(process.env.LOGIN_WITH_GOOGLE === "true" ||
              process.env.LOGIN_WITH_EMAIL === "true") && (
              <div style={{ padding: "24px 0" }}>
                <div className={classes.orLine}>
                  <Typography className={classes.orText}>
                    {t("login.OR")}
                  </Typography>
                </div>
              </div>
            )}
            {process.env.LOGIN_WITH_GOOGLE === "true" && (
              <Button
                variant="outlined"
                fullWidth
                style={{ fontSize: 14 }}
                onClick={handleLoginGoogle}
              >
                <img src="/img/Google_G_Logo.svg" style={{ marginRight: 15 }} />
                {t("login.login-with-google")}
              </Button>
            )}
            {process.env.LOGIN_WITH_EMAIL === "true" && (
              <Button
                variant="outlined"
                fullWidth
                style={{ fontSize: 14, marginTop: 12 }}
                onClick={handleLoginWithEmail}
              >
                <Email style={{ marginRight: 15, color: "#A0A4A8" }} />
                {t("login.login-with-email")}
              </Button>
            )}

            <div
              style={{
                bottom: "1rem",
                textAlign: "center",
                width: "90%",
                position: "absolute",
                maxWidth: "444px",
              }}
            >
              <Typography style={{ fontSize: 14 }}>
                {t("login.dont-have-account-yet?")}{" "}
                <span
                  style={{
                    color: `${process.env.PRIMARY_COLOR || "#2F9CF1"}`,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={handleRegister}
                >
                  {t("login.register-here")}
                </span>
              </Typography>
            </div>
          </div>
          <Drawer
            anchor="bottom"
            open={openPhoneCodePicker}
            onClose={() => setOpenPhoneCodePicker(false)}
            style={{ margin: "auto", maxWidth: 444, width: "100%" }}
          >
            <div style={{ margin: "auto", maxWidth: 444, width: "100%" }}>
              <PhoneCodePicker
                t={t}
                handleSelectPhoneCode={handleSelectPhoneCode}
                onClose={() => setOpenPhoneCodePicker(false)}
              />
            </div>
          </Drawer>
        </Container>
        <ReusableDialog
          open={register}
          button={t("login.register")}
          onSubmit={handleRegister}
          onClose={() => setRegister(false)}
          content={
            <div style={{ textAlign: "center" }}>
              <img src="/img/sorry.svg" />
              <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
                {t("login.havent-registered")}
              </Typography>
              <Typography style={{ fontSize: "12px", color: "#808080" }}>
                {t("login.lets-register")}
              </Typography>
            </div>
          }
        />
        <ReusableDialog
          open={isError}
          onClose={() => setIsError(false)}
          content={
            <div style={{ textAlign: "center" }}>
              <img src="/img/sorry.svg" />
              <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
                {t("register.oops")}
              </Typography>
              <Typography style={{ fontSize: "12px", color: "#808080" }}>
                {errorMessage}
              </Typography>
            </div>
          }
        />
        <Loading open={isLoading} onClose={() => setIsLoading(false)} />
      </Fragment>
    </div>
  );
};

export default Login;
